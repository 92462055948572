import {
  InventoryItem,
  InventorySingle,
  InventoryStatus,
} from "~/types/inventory";

export const useGetInventoryStatus = (
  item: InventorySingle | InventoryItem,
  noStockValue?: boolean
) => {
  const statusesForShowingValues = [
    InventoryStatus.IN_STOCK,
    InventoryStatus.OUT_OF_STOCK,
    InventoryStatus.LOW
  ]

  const status =
    {
      [InventoryStatus.IN_STOCK]: "In Stock",
      [InventoryStatus.OUT_OF_STOCK]: "Out of Stock",
      [InventoryStatus.LOW]: "Low",
      [InventoryStatus.DRAFT]: "Draft",
    }[item.stock_status] || "";

  return !noStockValue && statusesForShowingValues.includes(item.stock_status) && item.expected_stock
    ? `${status} (${item.expected_stock})`
    : status;
};
